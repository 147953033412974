import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(@Inject(DOCUMENT) private doc: any,
              private meta: Meta) {
  }

  createLinkForCanonicalURL(suffix: string): void {
    let link: HTMLLinkElement = this.doc.querySelector('link[rel="canonical"]');
    link.setAttribute('href', environment.appUrl + suffix);
    this.robotNoIndex();
  }

  updateMetaTag(name: string, content: string): void {
    this.meta.updateTag({name, content} as MetaDefinition);
  }

  private robotNoIndex(): void {
    if (environment.robotNoIndex) {
      this.meta.updateTag({name: 'robots', content: 'noindex'} as MetaDefinition);
    }
  }
}
